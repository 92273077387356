<template>
  <div class="text-center" v-if="resolutionScreen < 500">
    <v-snackbar v-model="shower" :timeout="-1" bottom>
      <div
        class="text-right mb-n2 mt-1"
        v-if="countrestoreerror > 0 || countcancel > 0"
      >
        <v-btn @click="$emit('closedialog')" icon x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card color="transparent" elevation="0" class="mt-3 pa-3 " width="320">
        <p v-if="statusrestore === 'start'">
          <span class="pl-2">{{ $t("backupfileandfolder.preparerestore") }}</span>
          <v-btn
            style="margin-left:10px; margin-top:-4px;"
            class="float-right"
            small
            color="secondary"
            @click="$emit('cancelselectall')"
          >
            {{ $t("dragdrop.cancel") }}
          </v-btn>
        </p>
        <p v-else-if="statusrestore === 'cancelselect'">
          <span class="pl-2">{{ $t("backupfileandfolder.cancelrestore") }}</span>
        </p>
        <p
          v-else-if="
            statusrestore === 'restore' &&
              calpercenrestore === 100 &&
              countrestoreerror === 0
          "
        >
          {{ $t("backupfileandfolder.restoresuccess") }}
          <span class="float-right"
            ><v-icon color="green">mdi-check-bold</v-icon></span
          >
        </p>
        <p v-else-if="statusrestore === 'restore' && calpercenrestore >= 0">
          <span class="pl-2" v-if="calpercenrestore !== 100">{{
            $t("backupfileandfolder.restoringfilefolder")
          }}</span>
          <v-btn
            v-if="countrestoreerror > 0"
            style="margin-top:-4px;"
            small
            text
            @click="$emit('detailrestore')"
          >
            {{ $t("backupfileandfolder.cannotrestore") }}&nbsp; {{ countrestoreerror }}
            {{ $t("dragdrop.file") }}
          </v-btn>
          <span class="float-right"
            >{{ countrestore }} / {{ filedata.length }} ({{
              calpercenrestore
            }}%)</span
          >
          <v-card-text class="text-right" v-if="calpercenrestore !== 100">
            {{ showdatarestore[countrestore - 1].file_name }}
            <v-btn
              style="margin-left:10px; margin-top:-4px;"
              small
              color="secondary"
              class="mr-n5"
              @click="$emit('cancelrestore')"
            >
              {{ $t("dragdrop.cancel") }}
            </v-btn>
          </v-card-text>
        </p>
        <p
          v-else-if="
            statusrestore === 'cancelrestore' && countcancel > 0
          "
        >
          <span class="pl-3">{{ $t("backupfileandfolder.cancelrestore") }}</span>
        </p>
      </v-card>
      <div
        class="mr-n2"
        v-if="statusrestore !== 'start' && calpercenrestore !== 100"
      >
        <v-progress-linear
          :value="calpercenonerestore"
          color="blue-grey"
          height="12"
          class="mx-0"
        >
          <strong>{{ calpercenonerestore }}%</strong>
        </v-progress-linear>
      </div>
    </v-snackbar>
  </div>
  <div class="text-center" v-else>
    <v-snackbar v-model="shower" :timeout="-1" bottom>
      <!-- ปุ่มปิด snackbar -->
      <div
        class="text-right mb-n2 mt-1"
        v-if="countrestoreerror > 0 || countcancel > 0"
      >
        <v-btn @click="$emit('closedialog')" icon x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card color="transparent" elevation="0" width="500" class="mt-3 pa-3">
        <!-- เริ่มทำการกู้คืนทั้งหมด (โชว์เมื่อกำลังวนยิง api select) -->
        <p v-if="statusrestore === 'start'">
          <span class="pl-2">{{ $t("backupfileandfolder.preparerestore") }}</span>
          <v-btn
            style="margin-left:10px; margin-top:-4px;"
            class="float-right"
            small
            color="secondary"
            @click="$emit('cancelselectall')"
          >
            {{ $t("dragdrop.cancel") }}
          </v-btn>
        </p>
        <p v-else-if="statusrestore === 'cancelselect'">
          <span class="pl-2">{{ $t("backupfileandfolder.cancelrestore") }}</span>
        </p>
        <p
          v-else-if="
            statusrestore === 'restore' &&
              calpercenrestore === 100 &&
              countrestoreerror === 0
          "
        >
          <span class="pl-2">{{ $t("backupfileandfolder.restoresuccess") }}</span>
          <span class="float-right"
            ><v-icon color="green">mdi-check-bold</v-icon></span
          >
        </p>
        <p
          v-else-if="
            statusrestore === 'cancelrestore' && countcancel > 0
          "
        >
          <span class="pl-2">{{ $t("backupfileandfolder.cancelrestore") }}</span>
        </p>
        <p v-else-if="statusrestore === 'restore' && calpercenrestore >= 0">
          <span class="pl-2" v-if="calpercenrestore !== 100">{{
            $t("backupfileandfolder.restoringfilefolder")
          }}</span>
          <v-btn
            v-if="countrestoreerror > 0"
            style="margin-top:-4px;"
            small
            text
            @click="$emit('detailrestore')"
          >
            {{ $t("backupfileandfolder.cannotrestore") }}&nbsp; {{ countrestoreerror }}
            {{ $t("dragdrop.file") }}
          </v-btn>
          <span class="float-right"
            >{{ countrestore }} / {{ filedata.length }} ({{
              calpercenrestore
            }}%)</span
          >
          <v-card-text
            class="text-right ma-0 pa-0 mb-n2"
            v-if="calpercenrestore !== 100"
          >
            <br />
            {{ showdatarestore[countrestore - 1].file_name }}
            <v-btn
              style="margin-left:10px; margin-top:-4px;"
              small
              color="secondary"
              @click="$emit('cancelrestore')"
            >
              {{ $t("dragdrop.cancel") }}
            </v-btn>
          </v-card-text>
        </p>
      </v-card>
      <div
        class="mr-n2"
        v-if="
          statusrestore !== 'start' &&
            calpercenrestore !== 100 &&
            countcancel === 0
        "
      >
        <v-progress-linear
          :value="calpercenonerestore"
          color="blue-grey"
          height="12"
          class="mx-0"
        >
          <strong>{{ calpercenonerestore }}%</strong>
        </v-progress-linear>
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import "sweetalert2/src/sweetalert2.scss";
export default {
  data: function() {
    return {};
  },
  comments: {},
  props: [
    "show",
    "countrestore",
    "statusrestore",
    "filedata",
    "showdatarestore",
    "countcancel",
    "countrestoreerror",
  ],
  data: function() {
    return {};
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closedialog");
        }
      },
    },
    // คำนวณเปอร์เซ็นการลบทั้งหมด
    calpercenrestore() {
      let percen = Math.round((this.countrestore * 100) / this.filedata.length);
      if (percen === Infinity) {
        return 100;
      } else {
        return percen;
      }
    },
    // คำนวณเปอร์เซ็นการลบต่อไฟล์/โฟลเดอร์
    calpercenonerestore() {
      if (this.showdatarestore.length === 0) {
        return 0;
      } else {
        let percenbackup = this.showdatarestore[this.countrestore - 1]
          .restore_status.value;
        return percenbackup;
      }
    },
  },
  methods: {},
};
</script>
<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>
